<template>
  <!-- 逾期统计 -->
  <div class="home">
    <div class="searchNav">
      <!-- <el-button
        class="button"
        icon="el-icon-document"
        type="primary"
        style="margin-left: 0"
        :loading="addloading"
        @click="downloadFile"
        >逾期统计逾期统计逾期统计逾期统计逾期统计</el-button
      > -->

      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
      </div>
    </div>
    <div class="searchNav">
      <div class="searchParams" style="justify-content: flex-start">
        <!-- <el-date-picker
          v-model="daterange"
          type="daterange"
          style="width: 250px; margin-left: 10px"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeTime"
        >
        </el-date-picker> -->
        <el-select class="input1" v-model="optionsKey" placeholder="请选择搜索类型" @change="selectChange">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input style="margin-left: 10px" class="input1" v-model="queryParam.Keywords"
          :placeholder="'请输入' + placeholder + '进行查询'" @keydown.enter.native="Getdata"></el-input>
        <el-button class="button" @click="Getdata" type="primary">查询</el-button>
      </div>
    </div>
    <el-table ref="multipleTable" :data="tableData" style="width: 100%" :max-height="height" v-loading="loading"
      @selection-change="handleSelectionChange">
      <el-table-column prop="Name" :label="label" width="600">
      </el-table-column>
      <el-table-column prop="QIANMONEY" label="逾期金额" width="600">
        <template slot-scope="scope">
          {{ toThousands(scope.row.OverdueAmount) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click.stop="handleDetailchange(scope.row)"
            >明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex">
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="margin-top: 8px; padding: 0; flex: 1" @current-change="currentChange" @size-change="handleSizeChange">
      </el-pagination>
      <el-row :gutter="20" style="width: 450px; text-align: right; font-weight: bold;padding:10px 0">
        <el-col :span="23">
          <div style="">总计逾期金额: {{ toThousands(totalReceiveSum) }}</div>
        </el-col>
      </el-row>
    </div>


    <!-- 明细组件 -->
    <overdueDetail ref="overdueDetail" :parentObj="{ ...this }" /> 



  </div>
</template>

<script>
import axios from "axios";
import radioGroup from "@/components/CompanyRadioGroup.vue";
import { toThousands } from "../../utils/Tools";
import MyBreadcrumb from '../../components/myBreadcrumb.vue';
// 引入明细组件
import overdueDetail from './overdueDetail.vue'
export default {
  name: "Home",
  components: {
    overdueDetail,
    radioGroup,
    MyBreadcrumb,
    // waybillForm
  },
  props: {
      parentObj: { type: Object },
    },
  data() {
    return {
      // 显示客户  还是人员
      label: '客户',
      // 日期
      daterange: [],
      // 默认下拉选项的值
      optionsKey: '客户',
      placeholder: "客户",
      // 金额格式化工具
      toThousands,
      // 搜索类型下拉数据
      options: [{
        value: '0',
        label: '客户'
      }, {
        value: '1',
        label: '业务员'
      }],
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      // 逾期统计列表数据
      tableData: [],
      // 查询列表参数
      queryParam: {
        CompanyId: "",
        StartDate: "",
        EndDate: "",
        Keywords: "",
        Type: 0,

      },
      loading: false,
      // 总金额
      totalReceiveSum: 0
    };
  },
  mounted() { },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 初始化
    toGetList() {
      this.pagination.current = 1
      // 获取逾期统计列表数据
      this.getDataList()
      // 获取逾期总金额统计数据
      this.GetCollectionAllStatistics()
    },
    // 搜索下拉改变
    selectChange(e) {
      console.log('选择的是', e);
      this.queryParam.Type = Number(e)
      if (e == 0) {
        this.label = '客户'
        this.placeholder = '客户'
      } else if (e == 1) {
        this.label = '业务员'
        this.placeholder = '业务员'
      }
      this.toGetList()
    },
    // 时间选择
    changeTime(e) {
      console.log(e);
      if (e) {
        this.queryParam.StartTime = e[0];
        this.queryParam.EndTime = e[1];
      } else {
        this.queryParam.StartTime = ''
        this.queryParam.EndTime = ''
      }

      // 获取逾期统计列表数据
      this.getDataList()
      // 获取逾期总金额统计数据
      this.GetCollectionAllStatistics();
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectRows = rows.map((item) => item.evidenceId);
    },
    radioComplete(e) {
      console.log(e);
      if (e) {
        this.queryParam.CompanyId = e;
        // 获取逾期统计列表数据
        this.getDataList();
        // 获取逾期总金额统计数据
        this.GetCollectionAllStatistics();
      }
    },
    // 公司切换
    radioChange(e) {
      
      if (e == "more") return;
      this.queryParam.CompanyId = e;
      this.getDataList();
      this.GetCollectionAllStatistics();
      console.log('公司切换',this.totalReceiveSum)
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
      // this.GetCollectionAllStatistics()
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
      // this.GetCollectionAllStatistics()
    },
    Getdata() {
      this.loading = true
      this.tableData=[]
      this.pagination.current = 1
      this.getDataList();
      // this.GetCollectionAllStatistics()
    },

    // 明细
    handleDetailchange (row){
      console.log('明细',row, this.queryParam.CompanyId, this.queryParam.Type)
      this.$refs.overdueDetail.openForm(row.Id, row.CompanyId, this.queryParam.Type?this.queryParam.Type:0)
    },

    // 获取列表逾期总金额统计接口
    GetCollectionAllStatistics() {
      this.$http
        .post("/BO/BIlling/GetOverdueAllStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            console.log("获取最终的逾期统计DIDDIDIDIDIID", resJson);
            if (resJson.Data == null) {
              this.totalReceiveSum = 0
            } else {
              this.totalReceiveSum = resJson.Data.OverdueAmount;
            }
          } else {
            this.loading = false;
          }
        });
    },

    // 获取逾期统计列表数据
    getDataList() {
      this.loading = true;
      let usr = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(usr.UserPhone);
      this.$http
        .post("BO/BIlling/GetOverdueStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            if(resJson.Data.length==0 || resJson.Data==null){
              this.loading=false
              this.tableData = []
              return
            }
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }else{
            this.loading=false
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}

.input1 {
  width: 200px;
  margin-left: 10px;
}
</style>
